import React, { useRef } from "react";
import { message } from "antd";

import RichText from "components/RichText";
import {
  LabeledInput,
  LabeledTextArea,
} from "components/LabeledInputs/LabeledInputs";
import { PrimaryButton } from "components/Buttons/Buttons";

import styles from "./FormSection.module.less";
/**
 * @param {GatsbyTypes.FormSectionFragment} props
 */
export default function FormSection(props) {
  const {
    html,
    frontmatter: {
      fileDownload,
      formFields,
      formName,
      formSubtitle,
      formTitle,
      submitLabel,
      title,
    },
  } = props;
  const downloadLink = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);

    // When there's an action, netlify processed it,
    // otherwise we're developing
    const action = form.getAttribute("action");

    const result = await fetch(action || "/", {
      method: "POST",
      body: data,
    });

    if (result.ok) {
      message.success("Submitted successfully");
      // Reset the form
      form.reset();
      if (downloadLink && downloadLink.current) {
        downloadLink.current.click();
      }
    } else {
      message.error("Couldn't submit the form. Are you online?");
    }
  };

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.descriptionSection}>
          <div className={styles.descriptionText}>
            <header>
              <h1 className={styles.descriptionTitle}>{title}</h1>
            </header>
            <div className={styles.descriptionText}>
              <RichText html={html} />
            </div>
          </div>
        </div>
        {/* Just leverage on browser API to simply initiate download */}
        {fileDownload && fileDownload.publicURL && (
          <a
            ref={downloadLink}
            className={styles.hidden}
            download={true}
            href={fileDownload.publicURL}
            rel="nofollow"
          >
            Download
          </a>
        )}

        <div className={styles.formSection}>
          <h2 className={styles.formTitle}>{formTitle}</h2>
          <p className={styles.formDescription}>{formSubtitle}</p>

          <form
            name={formName}
            method="post"
            data-netlify="true"
            data-netlify-honeypot="trap"
            onSubmit={handleSubmit}
          >
            <p className={styles.hidden}>
              <label>
                Don’t fill this out if you're human: <input name="trap" />
              </label>
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value={formName} />
            </p>
            {formFields.map(({ input, ...labelInputProps }) => {
              switch (input) {
                case "input":
                  return (
                    <LabeledInput
                      key={labelInputProps.name}
                      {...labelInputProps}
                    />
                  );
                case "textArea":
                  return (
                    <LabeledTextArea
                      key={labelInputProps.name}
                      {...labelInputProps}
                      rows={4}
                    />
                  );
                default:
                  return null;
              }
            })}
            <PrimaryButton
              title={submitLabel}
              htmlType="submit"
              className={styles.formSubmitButton}
            />
            <div className={styles.formDisclaimer}>
              By submitting this form, I confirm that Inex One may process my
              personal data and contact me.
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
