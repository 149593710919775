import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout/";
import FormSection from "components/FormSection/FormSection";
import SEO, { createQueryImageFromFile } from "components/SEO";

const DATE_PUBLISHED = new Date("2019-08-12T12:51:31+00:00");

export const pageQuery = graphql`
  fragment FormSection on MarkdownRemark {
    html
    frontmatter {
      title
      formName
      formTitle
      formSubtitle
      formFields {
        label
        name
        input
        type
        required
      }
      submitLabel
      fileDownload {
        publicURL
      }
    }
  }

  query FormPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...FormSection
      frontmatter {
        description
        sharingImage {
          image {
            ...OpenGraphImage
            ...TwitterImage
            ...StructuredDataImage
          }
          alt
          caption
        }
      }

      fields {
        dateModified
      }
    }
  }
`;

/**
 * @typedef {object} Props
 * @prop {Location} location
 * @prop {GatsbyTypes.FormPageQuery} data
 * @param {Props} props
 */
export default function FormPage(props) {
  const {
    location: { pathname },
    data: { markdownRemark: pageData },
  } = props;
  const [openGraph, twitter, sdPrimaryImage] = createQueryImageFromFile(
    pageData.frontmatter.sharingImage,
    ["openGraphImage.resize", "twitterImage.resize", "sdImage.resize"]
  );

  return (
    <Layout>
      <SEO
        pathname={pathname}
        title={pageData.frontmatter.title}
        description={pageData.frontmatter.description}
        images={{ openGraph, twitter, sdPrimaryImage }}
        datePublished={DATE_PUBLISHED}
        dateModified={new Date(pageData.fields.dateModified)}
      />
      <article>
        <FormSection {...pageData} />
      </article>
    </Layout>
  );
}
