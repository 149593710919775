import React from "react";
import styles from "./LabeledInputs.module.less";

/**
 * @typedef {Object} LabeledInputProps
 * @prop {string} label
 * @prop {"text" | "email" | "password"} [type]
 * @prop {string} [placeholder]
 *
 * @param {LabeledInputProps & (object)} props
 */
export function LabeledInput(props) {
  const { label, ...inputProps } = props;
  return (
    <div>
      <label className={styles.label} htmlFor={inputProps.name}>
        {label}
      </label>
      <input className={styles.input} id={inputProps.name} {...props} />
    </div>
  );
}

/**
 * @typedef {Object} LabeledTextAreaProps
 * @prop {string} label
 * @prop {string} [placeholder]
 *
 * @param {LabeledTextAreaProps & (object)} props
 */
export function LabeledTextArea({ label, ...props }) {
  return (
    <div>
      <label className={styles.label}>{label}</label>
      <textarea className={styles.input} {...props} />
    </div>
  );
}
